import { Text, useToast } from "@chakra-ui/react";
import { createContext, useState, ReactNode, useEffect } from "react";
import { Buffer } from "buffer";
import { User } from "../utils/api-data-types";
import useApi from "../hooks/useApi";

interface AuthContextData {
  authenticated: boolean;
  user: string | null;
  userIsAdmin: boolean | undefined;
  accessToken: string | null;
  setAccessToken: (token: string) => void;
  refreshToken: string | null;
  setRefreshToken: (token: string) => void;
  signIn(token: string): void;
  logOut(): void;
  displayName: string | null;
  userEmail: string | null;
}

const AuthContext = createContext({} as AuthContextData);

const AuthProvider = ({ children }: { children: ReactNode }) => {
  const toast = useToast();
  const { fetchUserByEmail, fetchLoginUsers } = useApi();
  const [isLoading, setIsLoading] = useState(true);

  const [user, setUser] = useState<string | null>(
    localStorage.getItem("displayName")
  );
  const [refreshToken, setRefreshToken] = useState<string | null>(
    localStorage.getItem("refreshToken")
  );
  const [userIsAdmin, setUserIsAdmin] = useState<boolean>(
    localStorage.getItem("userIsAdmin") === "true"
  );
  const [accessToken, setAccessToken] = useState<string | null>(
    localStorage.getItem("accessToken")
  );
  const [displayName, setDisplayName] = useState<string | null>(
    localStorage.getItem("displayName")
  );
  const [userEmail, setUserEmail] = useState<string | null>(
    localStorage.getItem("userEmail")
  );

  useEffect(() => {
    setIsLoading(false);
  }, []);

  const signIn = async (token: string) => {
    const tokenDecoded = Buffer.from(token, "base64").toString();

    const displayName = JSON.parse(tokenDecoded)?.displayName || null;
    const accessToken = JSON.parse(tokenDecoded)?.accessToken || null;
    const email = JSON.parse(tokenDecoded)?.email;
    const refreshToken = JSON.parse(tokenDecoded)?.refreshToken || null;

    localStorage.setItem("displayName", displayName);
    localStorage.setItem("refreshToken", refreshToken);
    localStorage.setItem("accessToken", accessToken);
    setDisplayName(displayName);
    setRefreshToken(refreshToken);
    setAccessToken(accessToken);

    let e = email;

    if (email) {
      localStorage.setItem("userEmail", email);
      setUserEmail(email);
    } else {
      const allUsers = await fetchLoginUsers(displayName);
      if (allUsers?.error) return [];
      const usersByName = allUsers?.filter((user: User) => {
        const userNameMatches = user.name === displayName;
        const groupMatches = process.env.REACT_APP_USER_GROUP === user.group;
        return userNameMatches && groupMatches;
      });

      if (usersByName.length === 1) {
        e = usersByName[0].email;
        setUserEmail(e);
      } else if (usersByName.length === 2) {
        const bothUsersMatch =
          usersByName[0].email === usersByName[1].email ||
          usersByName[0].role === usersByName[1].role;

        if (bothUsersMatch) {
          e = usersByName[0].email;
          setUserEmail(e);
        } else if (usersByName > 2) {
          toast({
            title: "Error",
            description:
              "Multiple users found with the same name. Please contact support.",
            status: "error",
            duration: 9000,
          });
        } else {
          const viewUser = usersByName.find(
            (user: User) => user.role === "VIEW_ONLY"
          );
          e = viewUser?.email;
          setUserEmail(e);
        }
      }
    }

    const dbUser = await fetchUserByEmail(email || e);
    delete dbUser.hashedPassword;

    localStorage.setItem("signedInUser", dbUser);
    setUser(displayName);

    const isAdmin = dbUser.role === "ADMIN";
    localStorage.setItem("userIsAdmin", isAdmin ? "true" : "false");
    setUserIsAdmin(isAdmin);

    setIsLoading(false);
  };

  const logOut = () => {
    localStorage.removeItem("displayName");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("signedInUser");
    localStorage.removeItem("userIsAdmin");
    localStorage.removeItem("userEmail");
    setUser(null);
    setAccessToken(null);
    setUserIsAdmin(false);
    setIsLoading(false);
    setUserEmail(null);
    setRefreshToken(null);
    setDisplayName(null);
  };

  return (
    <AuthContext.Provider
      value={{
        authenticated: !!accessToken,
        logOut,
        signIn,
        user,
        userIsAdmin,
        accessToken,
        setAccessToken,
        refreshToken,
        setRefreshToken,
        displayName,
        userEmail,
      }}
    >
      {isLoading ? <Text>Loading...</Text> : children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
