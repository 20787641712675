import {
  MenuItem,
  MenuItemProps,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";

const CategoryFilterItem: React.FC<MenuItemProps> = ({ children, ...rest }) => {
  const filterIconColor = useColorModeValue(
    "light.filterIconColor",
    "dark.filterIconColor"
  );

  const filterTextColor = useColorModeValue(
    "light.filterTextColor",
    "dark.filterTextColor"
  );

  return (
    <MenuItem px="12px" fontSize="13px" color={filterIconColor} {...rest}>
      <Text fontWeight="normal" color={filterTextColor}>
        {children}
      </Text>
    </MenuItem>
  );
};

export default CategoryFilterItem;
