import PptxGenJS from "pptxgenjs";
import * as XLSX from 'xlsx';

const exportToExcel = (data: any[]) => {
  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.json_to_sheet(data);

  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  
  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', 'dataTable.xlsx');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const exportCsv = (chartId: string, series: any[]) => {
  if(chartId !== undefined){
    const chart = ApexCharts.getChartByID(chartId);

    chart?.exports.exportToCSV({
      series,
      columnDelimiter: ",",
    });
  } else {
    const newData = series.map(item => {
      const { ["$hashCode"]: removed, ...newItem } = item;
      return newItem;
    })
      exportToExcel(newData)
  }
};

export const exportPpt = (chartId: string) => {
  ApexCharts.getChartByID(chartId)
    ?.dataURI()
    .then((img) => {
      const obj = Object(img);
      if (obj.hasOwnProperty("imgURI")) {
        const ppt = new PptxGenJS();
        const slide = ppt.addSlide();
        slide.addImage({
          data: obj.imgURI,
          x: "15%",
          y: "30%",
          w: 8.0,
          h: 2,
          sizing: { type: "contain", w: 8, h: 2 },
        });
        ppt.writeFile({
          fileName: `${chartId}-data`,
        });
      }
    });
};

export const exportAllChartsToCsv = (arrayId: string[], series: any[]) => {
  arrayId.forEach((element, i) => {
    const chart = ApexCharts.getChartByID(element);

    chart?.exports.exportToCSV({
      series: series[i],
      columnDelimiter: ";",
    });
  });
};

export const exportAllChartsToPpt = async (arrayId: string[]) => {
  const ppt = new PptxGenJS();

  for (const chart of arrayId) {
    const img = await ApexCharts.getChartByID(chart)?.dataURI();

    const obj = Object(img);
    if (obj.hasOwnProperty("imgURI")) {
      const slide = ppt.addSlide();
      slide.addImage({
        data: obj.imgURI,
        x: "15%",
        y: "30%",
        w: 8.0,
        h: 2,
        sizing: { type: "contain", w: 8, h: 2 },
      });
    }
  }
  ppt.writeFile({ fileName: "all-data" });
};

// const exportAllChartsToPdf = async () => {
//   const arrayId = [
//     "clustered-chart",
//     "donut-chart",
//     "stacked-chart",
//     "staked-chart",
//   ];

//   const pdf = new jsPDF();

//   for (const chart of arrayId) {
//     const img = await ApexCharts.getChartByID(chart)?.dataURI();

//     const obj = Object(img);
//     if (obj.hasOwnProperty("imgURI")) {
//       pdf.addImage(obj.imgURI, "PNG", 15, 40, 180, 100);
//     }
//     if (chart !== "staked-chart") {
//       pdf.addPage();
//     }
//   }
//   pdf.save("all-charts-data.pdf");
// };

// const exportPdf = () => {
//   ApexCharts.getChartByID(id)
//     ?.dataURI()
//     .then((img) => {
//       const obj = Object(img);
//       if (obj.hasOwnProperty("imgURI")) {
//         const pdf = new jsPDF();
//         pdf.addImage(obj.imgURI, "PNG", 15, 40, 180, 100);
//         pdf.save(`${id}-data.pdf`);
//       }
//     });
// };
