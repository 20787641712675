import { ColorMode } from "@chakra-ui/react";
import { ApexOptions } from "apexcharts";

interface DonutChartsConfigProps {
  theme: ColorMode;
  labels: string[];
  chartId: string;
  name: string;
}

enum ChartTheme {
  LIGHT_TEXT_COLOR = "#1E3047",
  DARK_TEXT_COLOR = "#D6E8FF",
}

export const donutChartsConfig = ({
  theme,
  labels,
  chartId,
  name,
}: DonutChartsConfigProps): ApexOptions => ({
  colors: 
  [
    "#F59D2A",
    "#B94527",
    "#5E6060",
    "#10B9C8",
    "#E0256C",
    "#419BBE",
    "#b31c7e",
    "#2fb39d",
  ],
  theme: {
    mode: theme,
  },
  chart: {
    type: "donut",
    id: chartId,
    background: theme === "dark" ? "#152130" : "#FFFFFF",
    toolbar: {
      show: false,
      export: {
        csv: {
          filename: name,
        },
      },
    },
  },
  dataLabels: {
    enabled: false,
    formatter: function (val) {
      return val + "%"
    },
  },
  labels,
  fill: {
    opacity: 1,
  },
  grid: {
    padding: {
      bottom: 0,
    },
  },
  stroke: {
    show: false,
  },
  tooltip: {
    shared: true,
    intersect: false,
    fillSeriesColor: false,
    theme,
  },
  legend: {
    position: "bottom",
    horizontalAlign: "center",
    itemMargin: {
      vertical: 0,
    },
    markers: {
      width: 12,
      height: 12,
    },
    labels: {
      colors:
        theme === "light"
          ? ChartTheme.LIGHT_TEXT_COLOR
          : ChartTheme.DARK_TEXT_COLOR,
    },
  },
});
