import {
  Flex,
  Text,
  Tr,
  useColorMode,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import BackButton from "../components/buttons/BackButton";
import CategoriesFilter from "../components/filters/CategoriesFilter/CategoriesFilter";
import PageTitle from "../components/global/PageTitle";
import NormalLayout from "../components/layouts/NormalLayout";
import Chart from "react-apexcharts";
import VerticalAndHorizontalTable from "../components/tables/VerticalAndHorizontalTable";
import VHTableRowHeader from "../components/tables/VHTableRowHeader";
import VHTableRowItem from "../components/tables/VHTableRowItem";
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import {
  convertChartData,
  generateChartConfig,
  generateChartTableHeader,
} from "../utils/charts";
import { ChartData } from "../@types/interfaces/app/dashboard-structure";
import { useDashboardStore } from "../stores/dashboard.store";
import ExportMenu from "../components/global/ExportMenu";
import { exportCsv, exportPpt } from "../utils/exports";

const ExpandedChart: React.FC = () => {
  const { colorMode } = useColorMode();
  const [width, setWidth] = useState<number>(0);

  const [formatAsCurrency, setFormatAsCurrency] = useState<boolean>(false);

  const navigate = useNavigate();
  const parent = useRef<HTMLDivElement>();

  //set chart width based on parent
  const loadWidth = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      parent.current = node;
      setWidth(node.getBoundingClientRect().width);
    }
  }, []);

  const cardBackground = useColorModeValue(
    "light.cardsBackground",
    "dark.cardsBackground"
  );

  const cardsBorderColor = useColorModeValue(
    "light.cardsBorderColor",
    "dark.cardsBorderColor"
  );

  useEffect(() => {
    const listener = () => {
      if (!parent.current) return;
      setWidth(parent.current.getBoundingClientRect().width);
    };

    if (chartData.chartType == 'clusteredColumn' || chartData.chartType == 'stakedBarC') {
        setFormatAsCurrency(true);
    }

    //ensure chart resize based on window resize
    window.addEventListener("resize", listener);

    return () => {
      window.removeEventListener("resize", listener);
    };
  }, []);

  const chartData = useLocation().state as ChartData;
  const dashboardsData = useDashboardStore((s) => s.dashboardsData);
  if (!chartData || !dashboardsData) return <Navigate to="/" />;

  const series = convertChartData(dashboardsData as any, chartData);
  const chartConfig = generateChartConfig(
    colorMode,
    chartData,
    dashboardsData as any
  );

  return (
    <>
      <NormalLayout>
        <Flex w="full" flexDir="column" px="24px" pb="28px">
          <Flex
            align="center"
            mt="16px"
            justify="space-between"
            w="full"
            as="header"
          >
            <Flex align="center">
              <BackButton onClick={() => navigate(-1)}>Back</BackButton>
              <PageTitle ml="40px">{chartData.name}</PageTitle>
            </Flex>
            <Flex>
              <CategoriesFilter />
              <ExportMenu
                ml="16px"
                exportCsv={() => exportCsv(chartData.chartId, series)}
                exportPpt={() => exportPpt(chartData.chartId)}
              />
            </Flex>
          </Flex>

          <VStack spacing="24px" mt="24px" w="full" flexDir="column">
            <Flex
              p="24px"
              borderWidth="1px"
              borderRadius="8px"
              borderColor={cardsBorderColor}
              bgColor={cardBackground}
              w="full"
            >
              <Flex ref={loadWidth} flexDir="column" minH="400px" w="full">
                <Text fontSize="16px" mb="16px" fontWeight="semibold">
                  Chart view
                </Text>
                <Chart
                  series={series}
                  options={{
                    ...chartConfig,
                    // chart: { ...configs?.chart, id: chartConfigs.id },
                  }}
                  type={chartConfig.chart?.type}
                  width={width}
                  height="100%"
                />
              </Flex>
            </Flex>
            <Flex
              hidden={chartData.chartType === "donutChart"}
              p="24px"
              borderWidth="1px"
              borderRadius="8px"
              borderColor={cardsBorderColor}
              bgColor={cardBackground}
              w="full"
            >
              <Flex flexDir="column" w="full">
                <Text fontSize="16px" mb="16px" fontWeight="semibold">
                  Table View
                </Text>
                <VerticalAndHorizontalTable
                  overflowX="auto"
                  w="full"
                  headers={generateChartTableHeader(chartConfig)}
                >
                  {series.map((s: any) => (
                    <Tr>
                      <VHTableRowHeader>{s.name}</VHTableRowHeader>
                      {s.data?.map((d: any) => (
                        <VHTableRowItem>{formatAsCurrency ? '$' + d.toLocaleString() : d}</VHTableRowItem>
                      ))}
                    </Tr>
                  ))}
                </VerticalAndHorizontalTable>
              </Flex>
            </Flex>
          </VStack>
        </Flex>
      </NormalLayout>
    </>
  );
};

export default ExpandedChart;
