import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  MenuButtonProps,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { IoMdArrowDropdown } from "react-icons/io";
import { RiFileExcel2Fill } from "react-icons/ri";
import { FaFilePowerpoint } from "react-icons/fa";

interface ExportMenuProps extends MenuButtonProps {
  exportCsv?: () => void;
  exportPpt?: () => void;
}

const ExportMenu: React.FC<ExportMenuProps> = ({
  exportCsv,
  exportPpt,
  ...props
}) => {
  const filterIconColor = useColorModeValue(
    "light.filterIconColor",
    "dark.filterIconColor"
  );

  const filterTextColor = useColorModeValue(
    "light.filterTextColor",
    "dark.filterTextColor"
  );

  const cardBackground = useColorModeValue(
    "light.cardsBackground",
    "dark.cardsBackground"
  );

  const selectedExportAsValue = useColorModeValue(
    "light.selectedExportAsValue",
    "dark.selectedExportAsValue"
  );

  const cardsBorderColor = useColorModeValue(
    "light.cardsBorderColor",
    "dark.cardsBorderColor"
  );

  const menuCardBackground = useColorModeValue(
    "light.menuCardBackground",
    "dark.menuCardBackground"
  );

  const menuCardShadow = useColorModeValue(
    "0px 34px 14px rgba(218, 0, 0, 0.01), 0px 19px 12px rgba(0, 0, 0, 0.03), 0px 9px 9px rgba(0, 0, 0, 0.05), 0px 2px 5px rgba(0, 0, 0, 0.06), 0px 0px 0px rgba(0, 0, 0, 0.06)",
    "none"
  );

  const menuCardTitleColor = useColorModeValue(
    "light.menuCardTitleColor",
    "dark.menuCardTitleColor"
  );

  // const exportAllChartsToCsv = () => {
  //   const arrayId = [
  //     "clustered-chart",
  //     "donut-chart",
  //     "stacked-chart",
  //     "staked-chart",
  //   ];

  //   for (const chartId of arrayId) {
  //     const chart = ApexCharts.getChartByID(chartId);

  //     if (chartId === "donut-chart") {
  //       chart?.exports.exportToCSV({
  //         series: modality?.flatMap((d) => d.Interactions),
  //         columnDelimiter: ";",
  //       });
  //     } else {
  //       chart?.exports.exportToCSV({
  //         series: overTime?.data,
  //         columnDelimiter: ";",
  //       });
  //     }
  //   }
  // };

  // const exportAllChartsToPpt = async () => {
  //   const arrayId = [
  //     "clustered-chart",
  //     "donut-chart",
  //     "stacked-chart",
  //     "staked-chart",
  //   ];

  //   const ppt = new PptxGenJS();

  //   for (const chart of arrayId) {
  //     const img = await ApexCharts.getChartByID(chart)?.dataURI();

  //     const obj = Object(img);
  //     if (obj.hasOwnProperty("imgURI")) {
  //       const slide = ppt.addSlide();
  //       slide.addImage({
  //         path: obj.imgURI,
  //         w: "100%",
  //         h: "100%",
  //       });
  //     }
  //   }
  //   ppt.writeFile({ fileName: "all-data" });
  // };

  return (
    <Menu>
      <MenuButton
        justify="flex-start"
        w="full"
        maxW="174px"
        align="center"
        h="48px"
        borderRadius="4px"
        px="16px"
        py="12px"
        textAlign="start"
        fontWeight="normal"
        bgColor={cardBackground}
        as={Button}
        _hover={{}}
        _active={{}}
        fontSize="14px"
        color={filterIconColor}
        rightIcon={<IoMdArrowDropdown />}
        borderColor={cardsBorderColor}
        borderWidth="1px"
        {...props}
      >
        Export As:{" "}
        <Text as="span" color={selectedExportAsValue}>
          Select
        </Text>
      </MenuButton>
      <MenuList
        bgColor={menuCardBackground}
        boxShadow={menuCardShadow}
        border="none"
        py="16px"
      >
        <Text
          fontSize="13px"
          pl="12px"
          mb="16px"
          fontWeight="semibold"
          textTransform="uppercase"
          color={menuCardTitleColor}
        >
          export as
        </Text>
        <VStack spacing="8px">
          {/* <MenuItem
            px="12px"
            fontSize="13px"
            color={filterIconColor}
            icon={<FiFileText fontSize="20px" />}
            onClick={exportAllChartsToPdf}
          >
            <Text color={filterTextColor}>PDF</Text>
          </MenuItem> */}
          <MenuItem
            px="12px"
            fontSize="13px"
            color={filterIconColor}
            icon={<RiFileExcel2Fill fontSize="20px" />}
            onClick={exportCsv}
          >
            <Text color={filterTextColor}>Excel (csv, xls)</Text>
          </MenuItem>
        </VStack>
      </MenuList>
    </Menu>
  );
};

export default ExportMenu;
