import { FiTag } from "react-icons/fi";
import { DashboardStructure } from "../@types/interfaces/app/dashboard-structure";

export const exampleStructure: DashboardStructure[] = [
  {
    name: "Programs",
    pages: [
      {
        name: "Page 1",
        templateArea: [
          `
            "events_total"
            "events_cost"
            "chart10"
            "chart11"
            "chart12"
            "chart13"
          `,
          null,
          `
            "events_total events_total events_cost events_cost"
            "chart10 chart10 chart10 chart11"
            "chart12 chart12 chart12 chart13"
          `,
          `
            "events_total events_total events_cost events_cost"
            "chart10 chart10 chart10 chart11"
            "chart12 chart12 chart12 chart13"
          `,
        ],
        KPIs: [
          {
            name: "Total Programs",
            KPIKey: "events_total",
            icon: FiTag,
            color: "#2E3192",
          },
          {
            name: "Total Costs",
            KPIKey: "events_cost",
            icon: FiTag,
            color: "#EC008C",
          },
        ],
        charts: [
          {
            name: "Event Status Over Time",
            dataKey: "events_by_status_over_time",
            hasCategories: true,
            chartType: "stackedColumn",
            hasLabels: false,
            chartId: "chart10",
          },
          {
            name: "Event Status",
            dataKey: "events_by_status",
            hasCategories: false,
            chartType: "donutChart",
            hasLabels: true,
            chartId: "chart11",
          },
          {
            name: "Event Cost by Status Over Time",
            dataKey: "cost_by_status_over_time",
            hasCategories: true,
            chartType: "clusteredColumn",
            hasLabels: false,
            tickAmount: 4,
            chartId: "chart12",
          },
          {
            name: "Events by Location Type",
            dataKey: "events_by_location",
            hasCategories: true,
            chartType: "stakedBar",
            hasLabels: false,
            chartId: "chart13",
          },
        ],
      },
      {
        name: "Page 2",
        templateArea: `
          "chart21"
          "chart21"
        `,
        charts: [
          {
            name: "",
            dataKey: "data_grid",
            hasCategories: false,
            chartType: "dataGrid",
            hasLabels: false,
            chartId: "chart21",
          },
        ],
      },
    ],
  },
  {
    name: "Expenses",
    pages: [
      {
        name: "Page 1",
        templateArea: [
          `
            "events_total"
            "events_cost"
            "chart30"
          `,
          null,
          `
            "events_total events_total events_cost events_cost"
            "chart30 chart30 chart30 chart30"
            "chart30 chart30 chart30 chart30"
          `,
          `
            "events_total events_total events_cost events_cost"
            "chart30 chart30 chart30 chart30"
            "chart30 chart30 chart30 chart30"
          `,
        ],
        KPIs: [
          {
            name: "Total Programs",
            KPIKey: "events_total",
            icon: FiTag,
            color: "#2E3192",
          },
          {
            name: "Total Costs",
            KPIKey: "events_cost",
            icon: FiTag,
            color: "#EC008C",
          },
        ],
        charts: [
          {
            name: "Cost by Expense over Status",
            dataKey: "cost_by_expense",
            hasCategories: true,
            chartType: "clusteredColumn",
            hasLabels: false,
            chartId: "chart30",
          },
        ],
      },
      {
        name: "Page 2",
        templateArea: `
          "chartdge chartdge"
          "chartdge chartdge"
        `,
        charts: [
          {
            name: "",
            dataKey: "data_grid_expense",
            hasCategories: false,
            chartType: "dataGrid",
            hasLabels: false,
            chartId: "chartdge",
          },
        ],
      },
    ],
  },
  {
    name: "Speakers",
    pages: [
      {
        name: "Page 1",
        templateArea: [
          `
            "events_total_completed"
            "honoraria_cost_completed"
            "events_total_canceled"
            "honoraria_cost_canceled"
            "chart50"
            "chart51"
          `,
          null,
          `
          "events_total_completed honoraria_cost_completed events_total_canceled honoraria_cost_canceled"
            "chart50 chart50 chart51 chart51"
            "chart50 chart50 chart51 chart51"
          `,
          `
            "events_total_completed honoraria_cost_completed events_total_canceled honoraria_cost_canceled"
            "chart50 chart50 chart50 chart50"
            "chart51 chart51 chart51 chart51"
          `,
        ],
        KPIs: [
          {
            name: "Total Completed Programs",
            KPIKey: "events_total_completed",
            icon: FiTag,
            color: "#10B9C8",
          },
          {
            name: "Total Canceled Programs",
            KPIKey: "events_total_canceled",
            icon: FiTag,
            color: "#B94527",
          },
          {
            name: "Total Completed Honoraria",
            KPIKey: "honoraria_cost_completed",
            icon: FiTag,
            color: "#10B9C8",
          },
          {
            name: "Total Canceled Honoraria",
            KPIKey: "honoraria_cost_canceled",
            icon: FiTag,
            color: "#B94527",
          },
        ],
        charts: [
          {
            name: "Event Status by Speaker",
            dataKey: "events_by_status_over_speaker",
            hasCategories: true,
            chartType: "stackedColumn",
            hasLabels: false,
            chartId: "chart50",
          },
          {
            name: "Honoraria by Speaker",
            dataKey: "honoraria_by_status_over_speaker",
            hasCategories: true,
            chartType: "clusteredColumn",
            hasLabels: false,
            chartId: "chart51",
          },
        ],
      },
      {
        name: "Page 2",
        templateArea: [
          `
            "chartsdg"
          `,
          null,
          `
            "chartsdg chartsdg chartsdg chartsdg"
            "chartsdg chartsdg chartsdg chartsdg"
          `,
          `
          "chartsdg chartsdg chartsdg chartsdg"
          "chartsdg chartsdg chartsdg chartsdg"
          `,
        ],
        charts: [
          {
            name: "",
            dataKey: "data_grid_speaker",
            hasCategories: false,
            chartType: "dataGrid",
            hasLabels: false,
            chartId: "chartsdg",
          },
        ],
      },
    ],
  },
  {
    name: "Attendees",
    pages: [
      {
        name: "Page 1",
        templateArea: [
          `
            "events_total"
            "attendee_total"
            "chart60"
            "chart61"
          `,
          null,
          `
            "events_total events_total attendee_total attendee_total"
            "chart60 chart60 chart61 chart61"
            "chart60 chart60 chart61 chart61"
          `,
          `
            "events_total events_total attendee_total attendee_total"
            "chart60 chart60 chart60 chart60"
            "chart61 chart61 chart61 chart61"
          `,
        ],
        KPIs: [
          {
            name: "Total Programs",
            KPIKey: "events_total",
            icon: FiTag,
            color: "#2E3192",
          },
          {
            name: "Total Attendees",
            KPIKey: "attendee_total",
            icon: FiTag,
            color: "#EC008C",
          },
        ],
        charts: [
          {
            name: "Attendees over Time",
            dataKey: "atteendees_over_time",
            hasCategories: true,
            chartType: "stackedColumn",
            hasLabels: false,
            chartId: "chart60",
          },
          {
            name: "Attendees by Credential ",
            dataKey: "events_by_credentials",
            hasCategories: true,
            chartType: "stackedColumn",
            hasLabels: false,
            chartId: "chart61",
          },
        ],
      },
      {
        name: "Page 2",
        templateArea: `
          "chart71"
          "chart71"
        `,
        charts: [
          {
            name: "",
            dataKey: "data_grid_attendee",
            hasCategories: false,
            chartType: "dataGrid",
            hasLabels: false,
            chartId: "chart71",
          },
        ],
      },
    ],
  },
];
