import { useState, useEffect, useContext } from "react";
import axios, { AxiosResponse } from "axios";
import useConfig from "./useConfig";
import { AuthContext } from "../providers/AuthProvider";

type PWLoginResponse = {
  base64Token?: string;
  user?: any;
  error?: any;
};

const useAuth = () => {
  const {
    logOut,
    signIn,
    authenticated,
    displayName,
    setAccessToken,
    setRefreshToken,
    refreshToken,
  } = useContext(AuthContext);

  const { apiUrl, ssoRefreshTokenUrl } = useConfig();
  const [refresh_token, setRefresh_token] = useState(refreshToken);

  useEffect(() => {
    if (!refresh_token) {
      setRefresh_token(refreshToken);
    }
  }, []);

  const refreshAccessToken = async () => {
    try {
      const response: AxiosResponse = await axios.post(ssoRefreshTokenUrl, {
        refresh_token,
      });
      const { accessToken: at, refreshToken: rt } = response.data;

      setAccessToken(at);
      setRefreshToken(rt);
    } catch (error) {
      logOut();
    }
  };

  const loginWithPassword = async (
    email: string,
    password: string
  ): Promise<PWLoginResponse> => {
    const url = apiUrl + "/login";

    try {
      const { data }: AxiosResponse = await axios.post(
        url,
        { email, password },
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": "true",
          },
          withCredentials: true,
        }
      );

      const { user, token: base64Token } = data;
      return { base64Token, user, error: null };
    } catch (error: any) {
      return { error: error.response.data.error };
    }
  };

  const resetPassword = async (email: string) => {
    const url = apiUrl + "/reset-password";

    try {
      const { data }: AxiosResponse = await axios.post(
        url,
        { email },
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": "true",
          },
          withCredentials: true,
        }
      );

      const success = data.success;
      if (!success) throw new Error("Error resetting password");
      return { error: null };
    } catch (error: any) {
      return {
        error: error?.response?.data?.error || "Error resetting password",
      };
    }
  };

  const setNewPassword = async (email: string, password: string) => {
    const url = apiUrl + "/set-password";

    try {
      const { data }: AxiosResponse = await axios.post(
        url,
        { email, password, require_pw_reset: false },
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": "true",
          },
          withCredentials: true,
        }
      );

      const success = data.success;
      if (!success) throw new Error("Error setting password");
      return { error: null };
    } catch (error: any) {
      return {
        error: error?.response?.data?.error || "Error resetting password",
      };
    }
  };

  return {
    isAuthenticated: authenticated,
    user: displayName,
    signIn,
    logOut,
    resetPassword,
    setNewPassword,
    refreshAccessToken,
    loginWithPassword,
  };
};

export default useAuth;
