import { useContext } from "react";
import axios, { AxiosError, AxiosResponse } from "axios";
import useConfig from "./useConfig";
import { AuthContext } from "../providers/AuthProvider";
import { User } from "../utils/api-data-types";

interface FailedRequest {
  onSuccess: (token: string) => void;
  onFailure: (err: AxiosError) => void;
}

const useApi = () => {
  const {
    accessToken,
    refreshToken,
    setAccessToken,
    setRefreshToken,
    logOut,
    userIsAdmin,
    displayName,
  } = useContext(AuthContext);
  const { apiUrl, ssoRefreshTokenUrl } = useConfig();

  let isRefreshing = false;
  let failedRequestQueue: FailedRequest[] = [];

  const api = axios.create({
    baseURL: apiUrl,
    withCredentials: true,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      user: JSON.stringify({
        displayName,
        userIsAdmin,
      }),
    },
  });

  api.interceptors.response.use(undefined, (e: AxiosError) => {
    if (e.response?.status !== 401) return Promise.reject(e);

    if (!refreshToken) return Promise.reject(e);

    const originalConfig = e.config;

    if (!isRefreshing) {
      isRefreshing = true;

      try {
        const { data }: any = api.post(ssoRefreshTokenUrl, {
          refresh_token: refreshToken,
        });
        const { accessToken: at, refreshToken: rt } = data;

        setAccessToken(at);
        setRefreshToken(rt);

        api.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;

        failedRequestQueue.forEach((request) => request.onSuccess(at));
        failedRequestQueue = [];
      } catch (e: any) {
        failedRequestQueue.forEach((request) => request.onFailure(e));
        failedRequestQueue = [];

        logOut();
      } finally {
        isRefreshing = false;
      }
    }

    return new Promise((resolve, reject) => {
      failedRequestQueue.push({
        onSuccess: (token: string) => {
          if (originalConfig.headers) {
            originalConfig.headers["Authorization"] = `Bearer ${token}`;
          }
          resolve(api(originalConfig));
        },
        onFailure: (err: AxiosError) => {
          reject(err);
        },
      });
    });
  });

  const group = process.env.REACT_APP_USER_GROUP;

  const fetchUserByEmail = async (email: string) => {
    const url = apiUrl + `/user/${email}`;

    try {
      if (!group) throw new Error("USER_GROUP environment variable not set");
      const { data }: AxiosResponse = await api.get(url);

      const user = data.user;
      if (!user) throw new Error("Error fetching users");
      return user;
    } catch (error: any) {
      return {
        error: error?.response?.data?.error || "Error fetching users",
      };
    }
  };

  const fetchUsers = async () => {
    const url = apiUrl + `/users/${group}`;

    try {
      if (!group) throw new Error("USER_GROUP environment variable not set");

      const { data }: any = await api.get(url);

      const users = data.users;
      if (!users) throw new Error("Error fetching users");
      return users;
    } catch (error: any) {
      return {
        error: error?.response?.data?.error || "Error fetching users",
      };
    }
  };

  const fetchLoginUsers = async (displayName: string) => {
    const url = apiUrl + `/login/users/${group}/${displayName}`;

    try {
      if (!group) throw new Error("USER_GROUP environment variable not set");

      const { data }: any = await api.get(url);

      const users = data.users;
      if (!users) throw new Error("Error fetching users");
      return users;
    } catch (error: any) {
      return {
        error: error?.response?.data?.error || "Error fetching users",
      };
    }
  };

  const deleteUser = async (email: string) => {
    const url = apiUrl + `/user/${email}`;

    try {
      const { data }: AxiosResponse = await api.delete(url);

      const success = data.success;
      if (!success) throw new Error("Error deleting user");
      return { success };
    } catch (error: any) {
      return {
        error: error?.response?.data?.error || "Error deleting user",
      };
    }
  };

  const updateUser = async (originalUser: User, newUser: User) => {
    const url = apiUrl + `/user/${originalUser.email}`;

    const updatedUser = {
      ...originalUser,
      ...newUser,
      group,
    };

    try {
      const { data }: AxiosResponse = await api.patch(url, updatedUser);

      const success = data.success;
      if (!success) throw new Error("Error modifying user");
      return { success };
    } catch (error: any) {
      return {
        error: error?.response?.data?.error || "Error modifying user",
      };
    }
  };

  const addUser = async (user: User) => {
    const url = apiUrl + `/user`;

    try {
      const { data }: AxiosResponse = await api.post(url, {
        ...user,
        group,
      });

      const success = data.success;
      if (!success) throw new Error("Error modifying user");
      return { success };
    } catch (error: any) {
      return {
        error: error?.response?.data?.error || "Error modifying user",
      };
    }
  };

  return {
    api,
    fetchUserByEmail,
    fetchUsers,
    deleteUser,
    updateUser,
    addUser,
    fetchLoginUsers,
  };
};

export default useApi;
