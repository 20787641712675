import { ColorMode } from "@chakra-ui/react";
import { ApexOptions } from "apexcharts";

interface ClusteredColumnConfigsProps {
  theme: ColorMode;
  categories: string[];
  chartId: string;
  tickAmount?: number;
  name: string;
}

enum ChartTheme {
  LIGHT_TEXT_COLOR = "rgba(30, 48, 71, 0.6)",
  DARK_TEXT_COLOR = "rgba(214, 232, 255, 0.6)",
  LIGHT_GRID_LINE_COLOR = "#EAEAEB",
  DARK_GRID_LINE_COLOR = " #254166",
}

export const clusteredColumnConfigs = ({
  theme,
  categories,
  chartId,
  tickAmount,
  name,
}: ClusteredColumnConfigsProps): ApexOptions => ({
  colors: 
  [
    "#F59D2A",
    "#B94527",
    "#5E6060",
    "#E0256C",
    "#419BBE",
    "#10B9C8",
    "#b31c7e",
    "#2fb39d",
  ],
    theme: {
      mode: theme,
    },
    plotOptions: {
      bar: {
        columnWidth: "90%",
        dataLabels: {
          total:{
            enabled: false
          },
        }
      },
    },
    chart: {
      type: "bar",
      background: theme === "dark" ? "#152130" : "#FFFFFF",
      stacked: true,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: true,
      },
      id: chartId,
    },
    yaxis: {
      tickAmount: tickAmount || 5,
      labels: {
        offsetX: -15, //remove padding
        style: {
          colors:
            theme === "light"
              ? ChartTheme.LIGHT_TEXT_COLOR
              : ChartTheme.DARK_TEXT_COLOR,
        },
        formatter: function (val) {
          return "$"+val.toLocaleString();
        },
      },
    },
    grid: {
      borderColor:
        theme === "light"
          ? ChartTheme.LIGHT_GRID_LINE_COLOR
          : ChartTheme.DARK_GRID_LINE_COLOR,
      padding: {
        left: -5,
      },
    },
    xaxis: {
      //   type: "datetime",
      labels: {
        hideOverlappingLabels: false,
        style: {
          fontSize: '10px',
          colors:
            theme === "light"
              ? ChartTheme.LIGHT_TEXT_COLOR
              : ChartTheme.DARK_TEXT_COLOR,
        },
      },
      categories,
    },
    tooltip: {
      theme,
      shared: true,
      intersect: false,
      x: {
        show: true,
        format: '',
        formatter: function(label, opts) { 
          let index = opts.dataPointIndex;
          let total = 0;
          
          for (let counter = 0; counter < opts.series.length; counter++) {
            if(isNaN(opts.series[counter][index]))
            {
              total = total
            } else {
              total = opts.series[counter][index] + total;
            }  
          }
  
          return label+ " | Total: $" + total.toLocaleString()  
        },
      },
    },
    legend: {
      position: "bottom",
      markers: {
        radius: 100,
        width: 12,
        height: 12,
      },
      labels: {
        colors: "#6594D1",
      },
    },
    fill: {
      opacity: 1,
    },
    dataLabels: {
      enabled: false,
      formatter: function(val) {
        return "$"+val.toLocaleString()
    }},
  });
  