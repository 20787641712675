import { ColorMode } from "@chakra-ui/react";
import { ApexOptions } from "apexcharts";

interface StackedBarConfigsProps {
  theme: ColorMode;
  categories: string[];
  chartId: string;
  tickAmount?: number;
  name: string;
}

enum ChartTheme {
  LIGHT_TEXT_COLOR = "rgba(30, 48, 71, 0.6)",
  DARK_TEXT_COLOR = "rgba(214, 232, 255, 0.6)",
  LIGHT_GRID_LINE_COLOR = "#EAEAEB",
  DARK_GRID_LINE_COLOR = " #254166",
  LIGHT_LEGEND_TEXT_COLOR = "#1E3047",
  DARK_LEGEND_TEXT_COLOR = " #D6E8FF",
}

export const stackedBarCurrencyConfigs = ({
  theme,
  categories,
  chartId,
  tickAmount,
  name,
}: StackedBarConfigsProps): ApexOptions => ({
  colors: 
    [
      "#0d213a",
      "#626f7f",
      "#3a5696",
      "#d8dbe1",
      "#ec008c",
      "#00aeef",
      "#b31c7e",
      "#2fb39d",
    ],
  theme: {
    mode: theme,
  },
  plotOptions: {
    bar: {
      horizontal: true,
      barHeight: "90%",
      dataLabels: {
        total:{
          enabled: true,
          offsetX: 6,
          style: {
            fontSize: '10px',
            fontWeight: 'bold',
          },
        },
      }
    },
  },
  chart: {
    type: "bar",
    stacked: true,
    background: theme === "dark" ? "#152130" : "#FFFFFF",
    toolbar: {
      show: false,
      export: {
        csv: {
          filename: name,
        },
      },
    },
    zoom: {
      enabled: true,
    },
    id: chartId,
  },
  dataLabels: {
    enabled: true,
    formatter: function (val) {
      return "$"+val.toLocaleString();
    },
    style: {
      fontSize: '10px',
    },
  },
  grid: {
    borderColor:
      theme === "light"
        ? ChartTheme.LIGHT_GRID_LINE_COLOR
        : ChartTheme.DARK_GRID_LINE_COLOR,
    padding: {
      left: -2,
    },
    xaxis: {
      lines: {
        show: true,
      },
    },
    yaxis: {
      lines: {
        show: false,
      },
    },
  },
  stroke: {
    show: false,
  },
  xaxis: {
    labels: {
      style: {
        colors:
          theme === "light"
            ? ChartTheme.LIGHT_TEXT_COLOR
            : ChartTheme.DARK_TEXT_COLOR,
      },
    },
    categories,
  },
  yaxis: {
    tickAmount: tickAmount || 5,
    labels: {
      style: {
        colors:
          theme === "light"
            ? ChartTheme.LIGHT_TEXT_COLOR
            : ChartTheme.DARK_TEXT_COLOR,
      },
      formatter: function (val) {
        return val.toLocaleString();
      },
    },
  },
  fill: {
    opacity: 1,
  },
  tooltip: {
    shared: true,
    intersect: false,
    theme,
  },
  legend: {
    markers: {
      radius: 100,
    },
    position: "bottom",
    horizontalAlign: "center",
    labels: {
      colors:
        theme === "light"
          ? ChartTheme.LIGHT_LEGEND_TEXT_COLOR
          : ChartTheme.DARK_LEGEND_TEXT_COLOR,
    },
  },
});
