import {
  Box,
  Flex,
  useColorModeValue,
  FlexProps,
  Text,
  Menu,
  MenuButton,
  IconButton,
  MenuList,
  VStack,
  MenuItem,
  Tr,
  Button,
  useColorMode,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { BsTable } from "react-icons/bs";
import { FiDownload } from "react-icons/fi";
import Chart from "react-apexcharts";
import { Chart as GoogleChart } from "react-google-charts";
import VerticalAndHorizontalTable from "../tables/VerticalAndHorizontalTable";
import VHTableRowHeader from "../tables/VHTableRowHeader";
import VHTableRowItem from "../tables/VHTableRowItem";
import { Link } from "react-router-dom";
import { RiFileExcel2Fill } from "react-icons/ri";
import { FaFilePowerpoint } from "react-icons/fa";
import { ChartData } from "../../@types/interfaces/app/dashboard-structure";
import { useDashboardStore } from "../../stores/dashboard.store";
import { IgrDataGridModule } from "igniteui-react-grids";
import { IgrNumericColumn  } from 'igniteui-react-grids';
import { IgrTextColumn } from 'igniteui-react-grids';
import { IgrDataGrid } from "igniteui-react-grids";
import {
  convertChartData,
  generateChartConfig,
  generateChartTableHeader,
  getGoogleChartType,
  getRawDataType,
} from "../../utils/charts";
import { exportCsv, exportPpt } from "../../utils/exports";

IgrDataGridModule.register();
interface CharBoxProps extends FlexProps {
  chartData: ChartData;
}

const ChartBox: React.FC<CharBoxProps> = ({ chartData, ...rest }) => {
  const { colorMode } = useColorMode();

  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);
  const chartRef = useRef(null);
  const dashboardsData = useDashboardStore((s) => s.dashboardsData);
  const series = convertChartData(dashboardsData as any, chartData);
  const chartConfig = generateChartConfig(
    colorMode,
    chartData,
    dashboardsData as any
  );

  const googleChartType = getGoogleChartType(chartData);
  const rawDataType = getRawDataType(chartData);

  const [tableView, setTableView] = useState(false);

  const parent = useRef<HTMLDivElement>();
  //set chart width based on parent
  const loadBounding = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      parent.current = node;
      setWidth(node.getBoundingClientRect().width);
      setHeight(node.getBoundingClientRect().height);
    }
  }, []);

  const cardBackground = useColorModeValue(
    "light.cardsBackground",
    "dark.cardsBackground"
  );

  const cardsBorderColor = useColorModeValue(
    "light.cardsBorderColor",
    "dark.cardsBorderColor"
  );

  const filterTextColor = useColorModeValue(
    "light.filterTextColor",
    "dark.filterTextColor"
  );

  const filterIconColor = useColorModeValue(
    "light.filterIconColor",
    "dark.filterIconColor"
  );

  const infoBoxDotsColor = useColorModeValue(
    "light.infoBoxDotsColor",
    "dark.infoBoxDotsColor"
  );

  useEffect(() => {
    const listener = () => {
      if (!parent.current) return;
      setWidth(parent.current.getBoundingClientRect().width);
    };

    //ensure chart resize based on window resize
    window.addEventListener("resize", listener);

    return () => {
      window.removeEventListener("resize", listener);
    };
  }, []);

  const menuCardBackground = useColorModeValue(
    "light.menuCardBackground",
    "dark.menuCardBackground"
  );

  const menuCardShadow = useColorModeValue(
    "0px 34px 14px rgba(218, 0, 0, 0.01), 0px 19px 12px rgba(0, 0, 0, 0.03), 0px 9px 9px rgba(0, 0, 0, 0.05), 0px 2px 5px rgba(0, 0, 0, 0.06), 0px 0px 0px rgba(0, 0, 0, 0.06)",
    "none"
  );

  const menuCardTitleColor = useColorModeValue(
    "light.menuCardTitleColor",
    "dark.menuCardTitleColor"
  );

  const noDataFoundText = useColorModeValue(
    "light.noDataFoundText",
    "dark.noDataFoundText"
  );

  return (
    <Flex
      flexDir="column"
      boxSize="full"
      borderRadius="8px"
      bgColor={cardBackground}
      borderWidth="1px"
      borderColor={cardsBorderColor}
      p="32px"
      {...rest}
    >
      <Flex
        justify="space-between"
        w="full"
        mb="10px"
        align="center"
        as="header"
      >
        <Link to="/expanded" state={chartData}>
          <Text fontSize="16px" fontWeight="semibold">
            {chartData.name}
          </Text>
        </Link>
        <Menu>
          <MenuButton
            color={infoBoxDotsColor}
            border="none"
            as={IconButton}
            _hover={{}}
            _active={{}}
            aria-label="Options"
            fontSize="24px"
            icon={<BiDotsVerticalRounded />}
            variant="outline"
          />
          <MenuList
            zIndex={"banner"}
            bgColor={menuCardBackground}
            boxShadow={menuCardShadow}
            border="none"
            py="16px"
          >
            <Text
              fontSize="13px"
              pl="12px"
              mb="16px"
              fontWeight="semibold"
              textTransform="uppercase"
              color={menuCardTitleColor}
            >
              CHART OPTIONS
            </Text>
            <VStack spacing="8px">
              <MenuItem
                px="12px"
                fontSize="13px"
                color={filterIconColor}
                icon={<BsTable fontSize="20px" />}
                onClick={() => setTableView(!tableView)}
                hidden={chartConfig.chart?.type === "donut" || chartData.chartType === "dataGrid"}
              >
                <Text color={filterTextColor}>
                  {tableView ? "View as Chart" : "View as Table"}
                </Text>
              </MenuItem>
              <Menu placement="end-start">
                <MenuButton
                  hidden={tableView}
                  as={Button}
                  variant="unstyled"
                  p="7px 12px"
                  fontSize="13px"
                  w="full"
                  textAlign="start"
                  display="flex"
                  alignItems="center"
                  iconSpacing="13.5px"
                  color={filterIconColor}
                  leftIcon={<FiDownload fontSize="20px" />}
                  fontWeight="400"
                  _focus={{}}
                >
                  <Text color={filterTextColor}>Export As</Text>
                </MenuButton>
                <MenuList
                  
                  bgColor={menuCardBackground}
                  boxShadow={menuCardShadow}
                  py="16px"
                >
                  <VStack>
                    <MenuItem
                      px="12px"
                      fontSize="13px"
                      color={filterIconColor}
                      icon={<RiFileExcel2Fill fontSize="20px" />}
                      onClick={() =>
                        exportCsv(chartConfig.chart?.id as string, series)
                      }
                    >
                      <Text color={filterTextColor}>Excel (csv, xls)</Text>
                    </MenuItem>
                  </VStack>
                </MenuList>
              </Menu>
            </VStack>
          </MenuList>
        </Menu>
      </Flex>
      <Box w="full" ref={loadBounding} flex="1">
        {tableView ? (
          <VerticalAndHorizontalTable
            maxW={width}
            maxH={height}
            headers={generateChartTableHeader(chartConfig)}
          >
            {series.map((s: any) => (
              <Tr>
                <VHTableRowHeader>{s.name}</VHTableRowHeader>
                {s.data?.map((d: any) => (
                  <VHTableRowItem>{d}</VHTableRowItem>
                ))}
              </Tr>
            ))}
          </VerticalAndHorizontalTable>
        ) : series && series.length > 0 && googleChartType ? (
          <GoogleChart
            ref={chartRef}
            chartType={chartData.chartType as any}
            width={width}
            height="100%"
            data={series}
            options={chartConfig}
          />
        ) : series && series.length > 0 && rawDataType && series[1]["Cost($)"]!= null ? (
          <IgrDataGrid
            ref={chartRef}
            dataSource={series}
            height="62vh"
            width="100%"
            autoGenerateColumns="true"
            defaultColumnMinWidth="160"
            rowHeight="100"
            summaryScope="Root"
            isColumnOptionsEnabled="true"
            isGroupCollapsable="true"
            groupHeaderDisplayMode="Combined"
            groupSummaryDisplayMode="RowBottom"
            columnMovingMode="Deferred"
            columnMovingAnimationMode="SlideOver"
            columnMovingSeparatorWidth="2"
            columnShowingAnimationMode="slideFromRightAndFadeIn"
            columnHidingAnimationMode="slideToRightAndFadeOut"
            selectionMode="SingleRow"
            cornerRadiusTopLeft="1"
            cornerRadiusTopRight="1"
            columnOptionsIconAlignment={1}
            headerSortIndicatorStyle={2}
            columnOptionsIconBehavior={1}
            pinnedAreaSeparatorWidth={0}
            isToolbarVisible="true"
            isToolbarColumnChooserVisible="true"
          >
            <IgrTextColumn field="Id" lineBreakMode={'WordWrap'}/>
            <IgrTextColumn field="Speaker" lineBreakMode={'WordWrap'}/>
            <IgrTextColumn field="Date" lineBreakMode={'WordWrap'}/>
            <IgrTextColumn field="Status" lineBreakMode={'WordWrap'}/>
            <IgrTextColumn field="Location Type" lineBreakMode={'WordWrap'}/>
            <IgrTextColumn field="Type" lineBreakMode={'WordWrap'}/>
            <IgrTextColumn field="Topic" lineBreakMode={'WordWrap'}/>
            <IgrNumericColumn field="Cost($)" positivePrefix="$"
            showGroupingSeparator="true" pinned="Right" horizontalAlignment='Left'/>
          </IgrDataGrid>
        )  : series && series.length > 0 && rawDataType && series[1]["Honoraria($)"]!= null ? (
          <IgrDataGrid
            ref={chartRef}
            dataSource={series}
            height="62vh"
            width="100%"
            autoGenerateColumns="true"
            defaultColumnMinWidth="162"
            rowHeight="100"
            summaryScope="Root"
            isColumnOptionsEnabled="true"
            isGroupCollapsable="true"
            groupHeaderDisplayMode="Combined"
            groupSummaryDisplayMode="RowBottom"
            columnMovingMode="Deferred"
            columnMovingAnimationMode="SlideOver"
            columnMovingSeparatorWidth="2"
            columnShowingAnimationMode="slideFromRightAndFadeIn"
            columnHidingAnimationMode="slideToRightAndFadeOut"
            selectionMode="SingleRow"
            cornerRadiusTopLeft="1"
            cornerRadiusTopRight="1"
            columnOptionsIconAlignment={1}
            headerSortIndicatorStyle={2}
            columnOptionsIconBehavior={1}
            pinnedAreaSeparatorWidth={0}
            isToolbarVisible="true"
            isToolbarColumnChooserVisible="true"
          >
            <IgrTextColumn field="Id" lineBreakMode={'WordWrap'}/>
            <IgrTextColumn field="Speaker" lineBreakMode={'WordWrap'}/>
            <IgrTextColumn field="Date" lineBreakMode={'WordWrap'}/>
            <IgrTextColumn field="Status" lineBreakMode={'WordWrap'}/>
            <IgrTextColumn field="Location Type" lineBreakMode={'WordWrap'}/>
            <IgrTextColumn field="Type" lineBreakMode={'WordWrap'}/>
            <IgrTextColumn field="Topic" lineBreakMode={'WordWrap'}/>
            <IgrNumericColumn field="Honoraria($)" positivePrefix="$"
            showGroupingSeparator="true" pinned="Right" horizontalAlignment='Left'/>
          </IgrDataGrid>
        ) : series && series.length > 0 && rawDataType  ? (
          <IgrDataGrid
            ref={chartRef}
            dataSource={series}
            height="62vh"
            width="100%"
            autoGenerateColumns="true"
            defaultColumnMinWidth="160"
            rowHeight="100"
            summaryScope="Root"
            isColumnOptionsEnabled="true"
            isGroupCollapsable="true"
            groupHeaderDisplayMode="Combined"
            groupSummaryDisplayMode="RowBottom"
            columnMovingMode="Deferred"
            columnMovingAnimationMode="SlideOver"
            columnMovingSeparatorWidth="2"
            columnShowingAnimationMode="slideFromRightAndFadeIn"
            columnHidingAnimationMode="slideToRightAndFadeOut"
            selectionMode="SingleRow"
            cornerRadiusTopLeft="1"
            cornerRadiusTopRight="1"
            columnOptionsIconAlignment={1}
            headerSortIndicatorStyle={2}
            columnOptionsIconBehavior={1}
            pinnedAreaSeparatorWidth={0}
            isToolbarVisible="true"
            isToolbarColumnChooserVisible="true"
          >
          </IgrDataGrid>
        )
          : series && series.length > 0 ? (
          <Chart
            ref={chartRef}
            series={series}
            options={{
              ...chartConfig,
            }}
            type={chartConfig.chart?.type}
            width={width}
            height="100%"
          />
        ) : (
          <Text
            margin="auto"
            color={noDataFoundText}
            fontWeight={500}
            fontSize="20px"
          >
            No data found
          </Text>
        )}
      </Box>
    </Flex>
  );
};

export default ChartBox;
